import { FC, PropsWithChildren } from "react";
import clx from "classnames";

type FormSectionProps = {
  title: string;
  description: string;
  separator?: boolean;
};

export const FormSection: FC<PropsWithChildren<FormSectionProps>> = ({
  title,
  description,
  children,
  separator = false,
}) => (
  <>
    <div className={clx(separator && "mb-10 sm:mb-0")}>
      <div className='md:grid md:grid-cols-3 md:gap-6 auto-rows-fr'>
        <div className='relative md:col-span-1'>
          <div className='px-4 sm:px-0'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              {title}
            </h3>
            <p className='mt-1 text-sm text-gray-600'>{description}</p>
          </div>
        </div>
        {children}
      </div>
    </div>

    {separator && (
      <div className='hidden sm:block' aria-hidden='true'>
        <div className='py-5'>
          <div className='border-t border-gray-200' />
        </div>
      </div>
    )}
  </>
);
