import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Store } from "./types";

const firebaseConfig = {
  apiKey: "AIzaSyCDe8BK7oMXkObchAw51scovlP4GoT6B34",
  authDomain: "fsprojects-b7347.firebaseapp.com",
  projectId: "fsprojects-b7347",
  storageBucket: "fsprojects-b7347.appspot.com",
  messagingSenderId: "786220287009",
  appId: "1:786220287009:web:974393eaa0939dfdf9cf60",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

export const fetchAllStores = httpsCallable<never, Store[]>(
  functions,
  "fetchAllStores"
);

export const handleDMCAForm = httpsCallable(functions, "handleDMCAForm");
