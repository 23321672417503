import clx from "classnames";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { VideoCameraIcon } from "@heroicons/react/outline";
import { array, object, string } from "yup";
import { useEffect, useState } from "react";
import Head from "react-helmet";

import {
  ErrorWrapper,
  FormCard,
  FormSection,
  OnSubmitDialog,
} from "./components";
import { fetchAllStores, handleDMCAForm } from "./firebase";
import { ContentItem, Store, Dialog } from "./types";

function App() {
  const [stores, setStores] = useState<Store[]>();
  const [dialog, setDialog] = useState<Dialog>();

  const close = () => window.location.reload();

  useEffect(() => {
    const loadStores = async () => {
      try {
        const { data: stores } = await fetchAllStores();

        setStores([
          {
            id: "",
            name: "Select a Store",
          },
          ...stores,
        ]);
      } catch (error: any) {
        setDialog({
          title: "Error loading stores",
          message: `There's been an error loading stores. Please try again later. (${error.code})`,
          success: false,
        });

        console.error(error);
      }
    };

    loadStores();
  }, []);

  return (
    <>
      <Head>
        <title>FemScat DMCA Takedown Notice Form</title>
      </Head>
      <div className='min-h-screen bg-gray-100'>
        <div className='max-w-6xl min-h-screen py-10 mx-auto space-y-10'>
          <div className='flex-1 min-w-0'>
            <h2 className='text-2xl font-bold leading-7 text-center text-gray-900 sm:text-3xl sm:truncate'>
              FemScat DMCA Takedown Notice Form
            </h2>
          </div>
          <Formik
            initialValues={{
              store: {
                id: "",
              },
              site: {
                name: "",
                email: "",
              },
              content: {
                items: [{ title: "", link: "" }] as ContentItem[],
              },
            }}
            validationSchema={object().shape({
              store: object().shape({
                id: string().trim().required("Please select a store."),
              }),
              site: object().shape({
                name: string().trim().required("Please enter a site name."),
                email: string()
                  .trim()
                  .email("Please enter a valid email address.")
                  .required("Please enter an email address."),
              }),
              content: object().shape({
                items: array().of(
                  object().shape({
                    link: string()
                      .trim()
                      .matches(
                        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/gi,
                        "Please enter a valid URL, starting with https:// or http://."
                      )
                      .required("Please enter a link."),
                    title: string().required("Please enter a URL."),
                  })
                ),
              }),
            })}
            onSubmit={async (v) => {
              try {
                await handleDMCAForm(v);

                setDialog({
                  title: "DMCA request submitted",
                  message:
                    "Your DMCA takedown notice has been submitted successfully.",
                  success: true,
                });
              } catch (error: any) {
                setDialog({
                  title: "Error submitting DMCA request",
                  message: `There's been an error submitting your DMCA request. Please try again later. (${
                    error.code ?? error.message
                  })`,
                  success: false,
                });
              }
            }}>
            {({ values, isSubmitting }) => (
              <Form className='py-5 mx-5' autoComplete='off'>
                <FormSection
                  title='About You'
                  description='This information about you will be used to contact you if necessary.'
                  separator>
                  <FormCard className='md:col-span-2'>
                    <div>
                      <label
                        htmlFor='store.id'
                        className='block text-sm font-medium text-gray-700'>
                        Store Name
                      </label>
                      <div className='mt-1'>
                        <Field
                          as='select'
                          name='store.id'
                          autoComplete='off'
                          data-form-type='other'
                          className='block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                          {stores?.map((s) => (
                            <option key={s.id} value={s.id}>
                              {s.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name='store.id'
                          render={(e) => <ErrorWrapper>{e}</ErrorWrapper>}
                        />
                      </div>
                    </div>
                  </FormCard>
                </FormSection>

                <FormSection
                  title='About the Site With Stolen Content'
                  description='This section is for information about the site hosting your content without your permission. This info will be used to contact them with a DMCA takedown notice.'
                  separator>
                  <FormCard className='md:col-span-2'>
                    <div>
                      <label
                        htmlFor='site.name'
                        className='block text-sm font-medium text-gray-700'>
                        Site Name
                      </label>
                      <div className='mt-1'>
                        <Field
                          type='text'
                          name='site.name'
                          id='site.name'
                          autoComplete='off'
                          data-form-type='other'
                          className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                        />
                        <ErrorMessage
                          name='site.name'
                          render={(e) => <ErrorWrapper>{e}</ErrorWrapper>}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='site.email'
                        className='block text-sm font-medium text-gray-700'>
                        Site DMCA Email
                      </label>
                      <div className='mt-1'>
                        <Field
                          type='text'
                          name='site.email'
                          id='site.email'
                          autoComplete='off'
                          data-form-type='other'
                          className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                        />
                        <ErrorMessage
                          name='site.email'
                          render={(e) => <ErrorWrapper>{e}</ErrorWrapper>}
                        />
                      </div>
                    </div>
                  </FormCard>
                </FormSection>

                <FormSection
                  title='About the Content'
                  description='This section is for information about the content you own which has been posted without your permission. Enter info about each piece of content here.'>
                  <FieldArray
                    name='content.items'
                    render={(arrayHelpers) => (
                      <>
                        {values.content.items.map((_v, index) => (
                          <FormCard
                            className={clx(index % 2 === 0 && "md:col-start-2")}
                            key={index}>
                            <>
                              <div>
                                <label
                                  htmlFor={`content.items[${index}].title`}
                                  className='block text-sm font-medium text-gray-700'>
                                  Content Title #{index + 1}
                                </label>
                                <div className='mt-1'>
                                  <Field
                                    type='text'
                                    name={`content.items[${index}].title`}
                                    id={`content.items[${index}].title`}
                                    autoComplete='off'
                                    data-form-type='other'
                                    className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                  />
                                  <ErrorMessage
                                    name={`content.items[${index}].title`}
                                    render={(e) => (
                                      <ErrorWrapper>{e}</ErrorWrapper>
                                    )}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor={`content.items[${index}].link`}
                                  className='block text-sm font-medium text-gray-700'>
                                  Stolen Site Link #{index + 1}
                                </label>
                                <div className='mt-1'>
                                  <Field
                                    type='text'
                                    name={`content.items[${index}].link`}
                                    id={`content.items[${index}].link`}
                                    autoComplete='off'
                                    data-form-type='other'
                                    className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                  />
                                  <ErrorMessage
                                    name={`content.items[${index}].link`}
                                    render={(e) => (
                                      <ErrorWrapper>{e}</ErrorWrapper>
                                    )}
                                  />
                                </div>
                              </div>
                              <div>
                                <button
                                  type='button'
                                  onClick={() => arrayHelpers.remove(index)}
                                  disabled={values.content.items.length === 1}
                                  className={
                                    "disabled:opacity-50 disabled:cursor-not-allowed w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  }>
                                  Delete
                                </button>
                              </div>
                            </>
                          </FormCard>
                        ))}
                        <div
                          className={clx(
                            values.content.items.length % 2 === 0 &&
                              "md:col-start-2",
                            "mt-5 md:mt-0"
                          )}>
                          <button
                            type='button'
                            className='relative block w-full h-full text-center border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            onClick={() =>
                              arrayHelpers.push({
                                title: "",
                                link: "",
                              })
                            }>
                            <VideoCameraIcon className='w-12 h-12 mx-auto text-gray-400' />
                            <span className='block mt-2 text-sm font-medium text-gray-900'>
                              Add another content item
                            </span>
                          </button>
                        </div>
                      </>
                    )}
                  />
                </FormSection>

                <div className='pt-5'>
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                    {isSubmitting ? (
                      <svg
                        className='w-5 h-5 mr-3 -ml-1 text-white animate-spin'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'>
                        <circle
                          className='opacity-25'
                          cx='12'
                          cy='12'
                          r='10'
                          stroke='currentColor'
                          strokeWidth='4'></circle>
                        <path
                          className='opacity-75'
                          fill='currentColor'
                          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                      </svg>
                    ) : (
                      "Submit DMCA Request"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <OnSubmitDialog dialog={dialog} close={close} />
    </>
  );
}

export default App;
