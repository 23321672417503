import { FC, PropsWithChildren } from "react";
import clx from "classnames";

type FormCardProps = {
  className?: string;
};

export const FormCard: FC<PropsWithChildren<FormCardProps>> = ({
  children,
  className,
}) => (
  <div className={clx(["mt-5 md:mt-0", className])}>
    <div className='overflow-hidden rounded-md shadow'>
      <div className='px-4 py-5 space-y-6 bg-white sm:p-6'>{children}</div>
    </div>
  </div>
);
